<template>
  <div class="story-container">
    <div class="title">
      <div>
        <b-button
          v-if="index"
          variant="outline-primary"
          :type="$const.PRIMARY_BUTTON"
          size="sm p-0"
          class="arrow-left"
          @click="$emit('replaceWithDown', index)"
        >
          <b-icon icon="caret-left-fill" />
        </b-button>
        <div v-else />
      </div>
      <div class="font-weight-bold">
        {{ Number(index) + 1 }}
      </div>
      <div>
        <b-button
          v-if="index !== carousel.length - 1"
          variant="outline-primary"
          :type="$const.PRIMARY_BUTTON"
          size="sm p-0"
          class="arrow-right"
          @click="$emit('replaceWithUp', index)"
        >
          <b-icon icon="caret-right-fill" />
        </b-button>
        <div v-else />
      </div>
    </div>
    <div
      v-if="actualImagePreview"
      class="story-viewer-cover-container"
    >
      <VStoryViewerCover
        :key="unicId"
        :story="story"
        :source="actualImagePreview"
        :source-type="story.contentFileContentType"
        :is-mobile="false"
        :muted="true"
        :active="true"
        :loop="true"
        class="story-viewer-cover"
      />
    </div>
    <StoryBackgroundForImage
      v-else
      :image-height="imageHeight"
      :image-width="imageWidth"
      :for-preview="true"
      class="background-image"
    />
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import { VStoryViewerCover } from '@doctis.front/doctis.designsystem';
import StoryBackgroundForImage from '@/components/Story/StoryBackgroundForImage';

export default {
  name: 'StoryContentPreview',
  components: { VStoryViewerCover, StoryBackgroundForImage },
  props: {
    index: {
      type: [Number, String],
      default: null,
    },
    carousel: {
      type: Array,
      default: () => ([]),
    },
    photoUrlProp: {
      type: String,
      default: '',
    },
    previewUrlProp: {
      type: String,
      default: '',
    },
    imageHeight: {
      type: Number,
      default: 250,
    },
    imageWidth: {
      type: Number,
      default: 150,
    },
    story: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      unicId: null,
    };
  },
  computed: {
    actualImagePreview() {
      return this.previewUrlProp || this.photoUrlProp;
    },
  },
  watch: {
    actualImagePreview() {
      this.onImageChange();
    },
  },
  methods: {
    onImageChange() {
      this.unicId = uuidv4();
    },
  },
};
</script>
<style lang="scss" scoped>
.story-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 5px;
}
.story-viewer-cover-container {
  position: relative;
  width: 150px;
  height: 250px;
}
.story-viewer-cover {
transform: scale(0.35);
  width: 400px;
  height: 700px;
  position: absolute;
  top: calc(-1 * ((700/250) - 1)*50%);
  left: calc(-1 * ((400/150) - 1)*50%);
  transform: scaleX(calc(150/400)) scaleY(calc(250/700));
}
</style>
