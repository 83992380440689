<template>
  <div class="events-wrapper">
    <div class="mb-3 h6">
      Событие {{ Number(index) + 1 }}
    </div>
    <div class="mr-5">
      <div class="mb-2 h6">
        Тип события
      </div>
      <v-select
        v-model="event.event"
        :reduce="event => event.event"
        :options="dictionaryEvents"
        placeholder="Тип события"
        label="title"
        class="crm-select event"
        :class="{'error': v.storyEvents.$each[index].event.$error}"
        @input="changeEvent"
      />
    </div>
    <transition-group name="list">
      <div
        v-for="(condition, idx) in event.conditions"
        :key="`condition-${idx}`"
      >
        <div class="events-wrapper-options mt-2">
          <div>
            <div class="mb-2 h6">
              Параметр
            </div>
            <v-select
              v-model="condition.parameter"
              :options="filteredDictionaryEvents?.parameters"
              placeholder="Параметр"
              label="parameter"
              :disabled="!event.event"
              :class="{'error': checkedConditionsErrors(index, idx, 'parameter')}"
              class="crm-select condition"
              @input="changeParameter($event, idx)"
            />
          </div>
          <div>
            <div class="mb-2 h6">
              Сравнение
            </div>
            <v-select
              v-model="condition.comparisonOperator"
              :reduce="comparisonOperator => comparisonOperator.value"
              :options="comparisonOperatorsArray(condition?.parameter?.dataType)"
              :class="{'error': checkedConditionsErrors(index, idx, 'comparisonOperator')}"
              placeholder="Сравнение"
              label="name"
              :disabled="!condition?.parameter"
              class="crm-select condition"
              @input="changeComparisonOperator($event, idx)"
            />
          </div>
          <div>
            <div class="mb-2 h6">
              Значение
            </div>
            <div
              v-if="condition?.parameter?.dataType === 'Array'"
            >
              <multiselect
                :id="idx"
                v-model="valueInMkb"
                tag-placeholder="добавить"
                placeholder="Значение"
                :preselect-first="false"
                :hide-selected="false"
                :options="options"
                class="multiselect-custom"
                :show-no-options="false"
                :taggable="true"
                @tag="(valueInMkb, id)=> {addValue(valueInMkb, id)}"
              >
                <span slot="singleLabel">Значение</span>
              </multiselect>
            </div>
            <SelectMultipleMkb
              v-else-if="condition?.parameter?.dataType === 'Mkb'"
              v-model="condition.value"
            />

            <div v-else>
              <b-input
                :key="condition.uniqId || condition.id"
                v-model="condition.value"
                :type="conditionValueType(condition.parameter)"
                :state="checkedInputValue(index, idx)"
                :min="conditionValueType(condition.parameter) === 'number'? 0 : ''"
                placeholder="Значение"
                :disabled="!condition.parameter || !condition.comparisonOperator"
              />
            </div>
          </div>
          <div
            class="btn-content"
          >
            <b-button
              variant="outline-danger"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="border border-danger"
              @click="removeCondition(idx)"
            >
              <b-icon icon="x" />
            </b-button>
          </div>
          <div
            v-if="condition?.parameter?.dataType === 'Array'"
            class="listValueInMkb"
          >
            <transition-group
              name="list"
              tag="p"
            >
              <div
                v-for="(value, i) in valueInMkb"
                :key="i"
                class="ml-1 mb-2"
              >
                <div class="d-flex">
                  <span class="listValuesInMkb">
                    {{ i + 1 }} - {{ value }}
                  </span>
                  <v-button
                    class="ml-1"
                    type="light"
                    label="X"
                    variant="accent"
                    @click="deleteValue(i, idx)"
                  />
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
    </transition-group>
    <div
      class="d-flex mt-3"
    >
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        :disabled="disabledRemoveBtn"
        @click="$emit('removeEvent')"
      >
        Удалить событие
      </b-button>
      <b-button
        v-if="event.conditions?.length < 5"
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        class="ml-2"
        @click="addCondition(event.conditions)"
      >
        Добавить параметр
      </b-button>
    </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import { VButton } from '@doctis.front/doctis.designsystem';
import {
  COMPARSION_OPERATORS_FOR_NUMERIC,
  COMPARSION_OPERATORS_FOR_STRING,
  COMPARSION_OPERATORS_FOR_ARRAY,
  COMPARSION_OPERATORS_FOR_MKB,
} from '@/helpers/consts';
import { v4 as uuidv4 } from 'uuid';
import SelectMultipleMkb from '@/components/Story/Settings/SelectMultipleMkb.vue';

export default {
  name: 'StoryEventsSettings',
  components: {
    Multiselect,
    VButton,
    SelectMultipleMkb,
  },
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    storyId: {
      type: [Number, String],
      default: null,
    },
    dictionaryEvents: {
      type: Array,
      default: () => ([]),
    },
    index: {
      type: Number,
      default: null,
    },
    disabledRemoveBtn: {
      type: Boolean,
      default: false,
    },
    v: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      COMPARSION_OPERATORS_FOR_MKB,
      COMPARSION_OPERATORS_FOR_NUMERIC,
      COMPARSION_OPERATORS_FOR_STRING,
      COMPARSION_OPERATORS_FOR_ARRAY,
      valueInMkb: [],
      options: [],
    };
  },
  computed: {
    filteredDictionaryEvents() {
      return this.dictionaryEvents.find((item) => item.event === this.event.event);
    },
  },
  mounted() {
    if (this.event.conditions.length !== 0) {
      this.valueInMkb = JSON.parse(this.event.conditions.filter((el) => el.parameter.parameter === 'mkb_code')[0].value);
    }
  },
  methods: {
    checkedConditionsErrors(index, idx, param) {
      return this.v.storyEvents.$each[index].conditions.$each[idx][param].$error;
    },
    checkedInputValue(index, idx) {
      return this.v.storyEvents.$each[index].conditions.$each[idx]
        .value.$anyError ? !this.v.storyEvents.$each[index]
          .conditions.$each[idx].value.$error : null;
    },
    removeCondition(index) {
      if (this.event.conditions[index].parameter !== null) {
        if (this.event.conditions[index].parameter.parameter !== null
        && this.event.conditions[index].parameter.parameter === 'mkb_code') {
          this.valueInMkb = [];
        }
      }
      this.event.conditions.splice(index, 1);
    },
    changeEvent(e) {
      this.$emit('changeType', e);
    },
    changeParameter(e, index) {
      const actualCondition = this.event.conditions[index];
      actualCondition.comparisonOperator = null;
      actualCondition.value = null;
      actualCondition.uniqId = uuidv4();
    },
    changeComparisonOperator(e, index) {
      const actualCondition = this.event.conditions[index];
      actualCondition.value = null;
      actualCondition.uniqId = uuidv4();
    },
    comparisonOperatorsArray(value) {
      if (value === 'Mkb') {
        return this.COMPARSION_OPERATORS_FOR_MKB;
      }
      if (value === 'Numeric') {
        return this.COMPARSION_OPERATORS_FOR_NUMERIC;
      }
      if (value === 'Array') {
        return this.COMPARSION_OPERATORS_FOR_ARRAY;
      }
      return this.COMPARSION_OPERATORS_FOR_STRING;
    },
    conditionValueType(value) {
      switch (value?.dataType) {
        case 'Numeric':
          return 'number';
        case 'String':
          return 'text';
        default:
          break;
      }
    },
    addValue(newValue, idx) {
      this.valueInMkb.push(newValue);
      this.$emit('addValueInMkb', { conditionIndex: idx, newValue });
    },
    addCondition() {
      this.$emit('addCondition');
    },
    deleteValue(indexElement, idx) {
      this.valueInMkb.splice(indexElement, 1);
      this.$emit('removeValueInMkb', { conditionIndex: idx, indexElem: indexElement });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.crm-select {
  .vs__dropdown-toggle {
    background: $white;
  }

  .vs__search {
    background: $white;
  }
}
.events-wrapper{
  width: 100%;
  min-height: 250px;
  padding: 15px;
  margin-bottom: 20px;
  background: rgb(243, 241, 241);
  box-shadow: 5px 5px 5px -5px rgba(0,0,0,0.2);
    &-options {
      display: grid;
      grid-template-columns: repeat(3, 1fr) 36px;;
      align-items: end;
      gap: 15px;
    }
    .event {
      width: 100%;
    }
}
.btn-content{
  width: 36px;
  margin: 0 5px 3px auto;
}
.error {
 outline-color: red;
}
.listValueInMkb {
  width: 100%;
  grid-column-start: 3;
}
.listValuesInMkb {
  display: inline-block;
  height: 30px;
  background-color: #fff;
  border-radius: 5px;
  padding: 8px 0px 8px 15px;
  font-size: 14px;
  width: 80%;
}
.multiselect-custom {
  & ::v-deep {
    .multiselect__placeholder {
      color: grey;
      font-size: 14px;
    }
    .multiselect__option--highlight,
    .multiselect__option--highlight:after,
    .multiselect__tag {
      background: #6E88F3;
      color: #f3f4f5;
    }
    .multiselect__tag-icon:hover {
      background: #6E88F3;
    }
  }
}
.list-enter-active, .list-leave-active {
  transition: all .7s;
}
.list-enter, .list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
