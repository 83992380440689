<template>
  <div class="fields-container p-3">
    <div class="story-photo-editor-container">
      <div class="story-photo-editor">
        <StoryPhotoEditor
          :photo-url-prop="content.fileUrlEdit"
          :preview-url-prop="content.preview"
          :story="content"
          :carousel="carousel"
          :for-content="true"
          @showPreview="showPreview"
          @upload="$emit('upload', $event)"
          @setPreview="$emit('setPreview', $event)"
          @remove="$emit('removeStoryCarousel')"
        />
      </div>
    </div>
    <div class="d-flex flex-column w-100 ml-4">
      <div class="d-flex justify-content-between">
        <div
          v-if="content.fileUrlEdit || content.preview"
          class="btn-container"
        >
          <div class="mb-2 label-btn-text">
            Кнопки на истории
          </div>
          <b-form-radio-group
            v-model="content.showButtons"
            :options="BUTTON_VALUES"
            value-field="value"
            text-field="title"
            size="md"
            button-variant="outline-primary"
            buttons
            class="equal-button-size"
            @change="$emit('change', $event)"
          />
        </div>
        <div
          class="h5 mt-1 mr-2"
          style="margin-left: auto;"
        >
          {{ index + 1 }} история
        </div>
      </div>
      <StoryButtonSettings
        v-if="content.showButtons"
        :buttons="content.buttons"
        :index="index"
        :carousel="carousel"
        :v="v"
        @addContentButtons="$emit('addContentButtons', $event)"
        @removeContentButtons="$emit('removeContentButtons', { buttonsIndex: $event, contentIndex: index})"
      />
    </div>
    <StoryFilePreview
      v-if="showFilePreview"
      :show="showFilePreview"
      :story="modifiedStory"
      :is-mobile="false"
      @hide-preview="hideImagePreview"
    />
  </div>
</template>
<script>
import StoryFilePreview from '@/components/Story/StoryFilePreview';
import StoryPhotoEditor from '../StoryPhotoEditor';
import StoryButtonSettings from './StoryButtonSettings';

export default {
  name: 'StoryContentWithButtonSettings',
  components: {
    StoryPhotoEditor,
    StoryButtonSettings,
    StoryFilePreview,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    carousel: {
      type: Array,
      default: () => ([]),
    },
    storyId: {
      type: [Number, String],
      default: null,
    },
    index: {
      type: [Number, String],
      default: null,
    },
    v: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      BUTTON_VALUES: [
        {
          value: false,
          title: 'Нет',
        },
        {
          value: true,
          title: 'Есть',
        },
      ],
      showFilePreview: false,
      modifiedStory: null,
    };
  },
  methods: {
    showPreview(event, show) {
      this.showFilePreview = show;
      this.modifiedStory = event;
    },
    hideImagePreview(value) {
      this.showFilePreview = value;
    },
  },
};
</script>
<style scoped>
::v-deep .equal-button-size .btn {
  width: 100px;
}
.fields-container {
  display: flex;
  width: 100%;
  gap: 15px;
}

.story-photo-editor-container {
  position:relative;
  width: 400px;
  height: 560px;
}

.story-photo-editor{
  width: 400px;
  height: 700px;
  position: absolute;
  top: calc(-1 * ((700/560) - 1)*50%);
  left: calc(-1 * ((400/300) - 1)*50%);
  transform: scaleX(calc(300/400)) scaleY(calc(560/700));
  box-shadow: 0px 0px 0px 8px#D0D2D5;
}
.btn-container{
  margin: 0 0 10px 10px;
}

.label-btn-text {
  font-weight: 500;
  line-height: 18px;
  font-size: 14px;
  color: #979797;
}

</style>
