<template>
  <div class="btn-options-container">
    <div
      v-for="(button, idx) in buttons"
      :key="`btn-${idx}`"
      class="btn-fields-container"
    >
      <div class="btn-fields mb-4">
        <div class="mr-3">
          <div class="mb-2 h6 btn-color-text">
            Текст (макс 30 симв)
          </div>
          <b-input
            v-model="button.text"
            placeholder="Текст кнопки"
            style="width: 260px;"
            :state=" v.storyСarousel.$each[index].buttons.$each[idx].text"
          />
        </div>
        <div class="mr-3">
          <div class="mb-2 h6 btn-color-text">
            Тип кнопки
          </div>
          <v-select
            v-model="button.type"
            :reduce="type => type.value"
            :options="filteredButtonsType"
            placeholder="Тип"
            label="name"
            class="crm-select"
            style="width: 260px;"
            :class="{'error': checkedStoryСarouselErrors(index, idx, 'type')}"
            @input="changeButtonType($event, idx)"
          />
        </div>
        <div
          v-if="button.type === 'Link'"
          class="mr-3"
        >
          <div class="mb-2 h6 btn-color-text">
            Ссылка
          </div>
          <b-input
            v-model="button.value"
            placeholder="Название ссылки"
            :state="v.storyСarousel.$each[index].buttons.$each[idx].value"
          />
        </div>
        <div
          class="btn-content"
        >
          <b-button
            v-if="buttons.length > 1"
            variant="outline-danger"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="mt-3"
            :disabled="buttons.length <= 1"
            @click="$emit('removeContentButtons', idx)"
          >
            <b-icon icon="trash" />
          </b-button>
        </div>
      </div>
      <div
        class="d-flex flex-column"
        style="gap: 20px;"
      >
        <story-button-settings-item
          v-for="(settings, settingIdx) in getButtonSettings(button)"
          :key="settingIdx"
          class="btn-color-text"
          v-bind="settings"
          :selected-option="button[settings.buttonKey] ?? ''"
          @select="(value) => changeButton(idx, settings, value)"
        />
      </div>
    </div>

    <b-button
      :variant="buttons.length <= 1? 'primary': 'light'"
      :type="$const.PRIMARY_BUTTON"
      size="sm"
      :disabled="buttons.length > 1"
      class="add-button-btn"
      :class="{'disabled-btn': buttons.length > 1}"
      @click="$emit('addContentButtons')"
    >
      <span class="add-button-btn__text">Добавить кнопку</span>
    </b-button>
  </div>
</template>

<script>
import StoryButtonSettingsItem from '@/components/Story/Content/StoryButtonSettingsItem.vue';
import {
  BUTTON_TYPE,
  BUTTON_VARIANT,
  BUTTON_AVAILABLE_VARIANTS,
} from '@doctis.front/doctis.designsystem/stories/components/button/options';

const DEFAULT_TEXT_BUTTON = 'Следующий слайд';

export default {
  name: 'StoryButtonSettings',
  components: {
    StoryButtonSettingsItem,
  },
  props: {
    buttons: {
      type: Array,
      default: () => ([]),
    },
    index: {
      type: [Number, String],
      default: null,
    },
    v: {
      type: Object,
      default: () => ({}),
    },
    carousel: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      BUTTON_TYPE,
      BUTTON_VARIANT,
      BUTTON_AVAILABLE_VARIANTS,
      BUTTON_TYPES: [
        {
          name: 'Ссылка',
          value: 'Link',
        },
        {
          name: 'След. слайд',
          value: 'MoveToNextCarouselItemAction',
        },
      ],
      buttonsStyleSelector: {},
      rightIcon: {
        name: 'm/arrow_down_chevron',
      },
      buttonSettings: [
        {
          title: 'Стиль кнопки', buttonKey: 'style', optionKey: 'type', options: BUTTON_TYPE,
        },
        {
          title: 'Цвет кнопки', buttonKey: 'variant', optionKey: 'variant', options: BUTTON_VARIANT,
        },
      ],
    };
  },
  computed: {
    filteredButtonsType() {
      return this.carousel.length - 1 === this.index ? this.BUTTON_TYPES.filter((item) => item.value === 'Link') : this.BUTTON_TYPES;
    },
  },
  created() {

  },
  methods: {
    getButtonSettings(button) {
      return [
        {
          title: 'Стиль кнопки', buttonKey: 'style', optionKey: 'type', options: BUTTON_TYPE,
        },
        {
          title: 'Цвет кнопки',
          buttonKey: 'variant',
          optionKey: 'variant',
          options: Object.fromEntries(BUTTON_AVAILABLE_VARIANTS[button.style.toLowerCase()]
            .map((value) => [value, value])),
          additionalOptions: {
            type: button.style.toLowerCase(),
          },
        },
      ];
    },
    checkedStoryСarouselErrors(index, idx, param) {
      return !this.v.storyСarousel.$each[index].buttons.$each[idx][param];
    },
    changeButtonType(event, idx) {
      if (event === 'MoveToNextCarouselItemAction') {
        this.buttons[idx].text = DEFAULT_TEXT_BUTTON;
      }
    },

    changeButton(idx, settings, value) {
      console.log('settings.buttonKey----', settings.buttonKey);
      console.log('value----', value);
      this.buttons[idx][settings.buttonKey] = value;
      // this.variants = BUTTON_AVAILABLE_VARIANTS[this.type];
      if (settings.buttonKey === 'style') {
        this.type = value;
      }
    },
    selectButtonType(type, idx) {
      this.buttons[idx].style = type;
    },
    selectButtonVariant(variant, idx) {
      this.buttons[idx].variant = variant;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.equal-button-size .btn {
  width: 70px;
  margin: 15px;
  border-radius: 15px!important
}
::v-deep.crm-select {
  .vs__dropdown-toggle {
    background: $white;
  }

  .vs__dropdown-menu {
    max-height: 150px;
  }

  .vs__search {
    background: $white;
  }
}
.btn-options-container {
  width: 100%;
  .btn-fields-container{
      background-color: #F3F4F5;
      padding: 16px;
      margin: 10px 10px 20px 10px;
      border-radius: 5px;
      border: 1px solid #D0D2D5;
    .btn-fields {
      display: flex;
      align-items: center;

      border-radius: 5px;
    }
  }
}
.btn-style{
  margin: 0 10px;
  border-radius: 15px;
}

.btn-content{
  margin-top: 10px;
  align-self: center;
}
.error {
 outline-color: red;
}
.add-button-btn {
  width: 180px;
  height: 46px;
  border-radius: 8px;
  padding: 12px 16px 12px 16px;
  margin: 10px 0 0 10px;

  &__text {
    font-weight: 500;
    font-size: 16px;
  }
}
.disabled-btn {
  background-color: rgb(228, 224, 224);
  border-radius: 5px;
  font-weight: 600;
  color: rgb(180, 174, 174);
}

.btn-color-text {
  color: #7F838D;
}
</style>
