<template>
  <div class="content p-3">
    <div class="d-flex flex-column">
      <h6 class="title mb-3">
        Обложка истории
      </h6>
      <div class="d-flex mb-3">
        <div>
          <div class="mb-1">
            Обложка
          </div>
          <StoryPhotoEditor
            :photo-url-prop="story.fileUrlEdit"
            :preview-url-prop="story.previewAvatar"
            :story="story"
            @upload="$emit('uploadAvatar', $event)"
            @setPreview="$emit('setAvatarPreview', $event)"
            @miniPicture="$emit('setMiniPicture', $event)"
          />
        </div>
        <div class="ml-4">
          <div class="d-flex align-items-center mb-2">
            <div class="mr-2">
              Заголовок
            </div>
            <span
              style="color: rgb(199, 193, 193)"
            >
              (Максимум символов 30, включая пробелы)
            </span>
          </div>
          <b-form-input
            v-model="story.title"
            size="sm"
            :state="v.$anyError ? !v.story.title.$error : null"
            placeholder="Максимум символов 30"
            style="width: 270px;"
          />

          <p
            v-if="story.title"
            class="mt-2"
            style="color: rgb(199, 193, 193)"
          >
            Количество введённых символов: {{ story.title.length }}
          </p>
        </div>
      </div>
      <div>
        <div class="content-title">
          Контент
        </div>
        <div
          v-if="carousel.length > 1"
          class="stories-preview-wrapper custom-scrollbar"
        >
          <div
            v-for="(content, index) in carousel"
            :key="`preview-${index}`"
            class="stories-preview-carousel"
          >
            <StoryContentPreview
              :index="index"
              :carousel="carousel"
              :story="content"
              :photo-url-prop="content.fileUrlEdit"
              :preview-url-prop="content.preview"
              class="preview-carousel-container"
              @replaceWithDown="$emit('replaceWithDown', index)"
              @replaceWithUp="$emit('replaceWithUp', index)"
            />
          </div>
        </div>
        <div class="stories-wrapper">
          <div
            v-for="(content, index) in carousel"
            :key="`content-${index}`"
            class="content-stories-carousel"
          >
            <StoryContentWithButtonSettings
              :story-id="storyId"
              :index="index"
              :content="content"
              :carousel="carousel"
              :v="v"
              @addContentButtons="$emit('addContentButtons', {contentIndex: index})"
              @removeContentButtons="$emit('removeContentButtons', { buttonsIndex: $event, contentIndex: index})"
              @upload="$emit('uploadContentFile', {file: $event, contentIndex: index})"
              @setPreview="$emit('setContentPreview', {preview: $event, contentIndex: index})"
              @change="$emit('changeShowButtons', {historyIndex: index, value: $event})"
              @removeStoryCarousel="$emit('removeStoryCarousel', index)"
            />
          </div>
        </div>
        <b-button
          variant="outline-primary"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          class="mt-1"
          @click="$emit('storyContentAdd')"
        >
          Добавить историю
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import StoryPhotoEditor from '../StoryPhotoEditor';
import StoryContentPreview from './StoryContentPreview';
import StoryContentWithButtonSettings from './StoryContentWithButtonSettings';

export default {
  name: 'StoryContent',
  components: {
    StoryPhotoEditor,
    StoryContentPreview,
    StoryContentWithButtonSettings,
  },
  props: {
    story: {
      type: Object,
      default: () => ({}),
    },
    storyId: {
      type: [Number, String],
      default: null,
    },
    carousel: {
      type: Array,
      default: () => ([]),
    },
    v: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      preview: null,
    };
  },
  methods: {
    setMiniPicture(value) {
      console.log('value ---', value);
      console.log('this.story ---', this.story);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100vh;
  background-color: #f3f3f3;
  overflow-y: auto;
  border: 1px solid #DEE2E6;
}

.content-title {
  font-size: 20px;
  font-weight: 500;
}
.title {
  font-size: 20px;
  font-weight: 600;
}
.stories-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
}

.content-stories-carousel {
  display: flex;
  padding: 5px;
  margin: 10px 0;
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
.stories-preview-wrapper {
  display: flex;
  overflow-x: auto;
  padding: 5px 0;
}
.stories-preview-carousel {
  display: flex;
  padding: 5px;
  margin: 0 10px;
  background: rgb(245, 242, 242);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.btn-settings-container{
  overflow-y: auto;
}
.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: #e4f3ff;
  }

  &::-webkit-scrollbar-thumb {
    background: #879ba8;
    border-radius: 5px;
  }
}

</style>
