<template>
  <div>
    <div class="mb-2 h6">
      {{ title }}
    </div>
    <div class="custom-class-btn">
      <v-button
        v-for="(button, index) in buttons"
        :key="index"
        v-bind="button"
        class="mr-2"
        @click="selectButton(button)"
      />
    </div>
  </div>
</template>

<script>
import { VButton } from '@doctis.front/doctis.designsystem';

export default {
  components: {
    VButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    additionalOptions: {
      type: Object,
      default: () => null,
    },
    selectedOption: {
      type: String,
      required: true,
    },
    optionKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    buttons() {
      return Object.values(this.options)
        .map((option) => {
          const isOptionSelected = this.checkIsSelected(option);

          return {
            label: isOptionSelected ? 'Выбрано' : option,
            rightIcon: isOptionSelected ? { name: 'm/arrow_down_chevron' } : null,
            [this.optionKey]: option,
            ...(this.additionalOptions ?? {}),
          };
        });
    },
  },
  methods: {
    checkIsSelected(option) {
      return option === this.selectedOption?.toLowerCase();
    },
    selectButton(button) {
      this.$emit('select', button[this.optionKey]);
    },
  },
};
</script>
<style lang="scss" scoped>
  .custom-class-btn {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
</style>
