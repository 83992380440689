<template>
  <div class="multi-select">
    <div
      class="dropdown w-full flex items-center justify-between bg-white rounded cursor-pointer"
      @click="toggleDropdown"
    >
      <div class="flex items-center">
        <div
          v-if="!selectedItems.length"
          class="w-full"
        >
          Выберите МКБ
        </div>
        <div class="flex flex-wrap gap-1">
          <div
            v-for="(item, index) in selectedItems"
            :key="index"
            class="tag"
          >
            <div>{{ item }}</div>
            <b-icon
              icon="x"
              class="ml-2"
              @click="removeItem(item)"
            />
          </div>
        </div>
      </div>
      <b-icon
        icon="chevron-down"
        class="ml-2"
      />
    </div>
    <div
      v-if="isView"
      class="list"
    >
      <div class="search">
        <b-icon icon="search" />
        <input
          v-model="search"
          type="text"
          placeholder="Поиск по МКБ"
          @input="onSearch"
        >
      </div>
      <div
        v-if="groupedData.length"
        class="list-mkb custom-scrollbar"
      >
        <div
          v-for="(category, index) in groupedData"
          :key="index"
        >
          <label class="checkbox">
            <input
              type="checkbox"
              :checked="isCategorySelected(category.category)"
              @change="toggleCategory(category.category)"
            >
            <span class="font-medium">
              {{ category.category }} {{ category.nameCategory }}
            </span>
          </label>
          <div
            v-for="item in category.diagnoses"
            :key="item.diagnosis"
          >
            <label
              v-if="item.isViewChose"
              class="checkbox ml-2"
            >
              <input
                type="checkbox"
                :checked="isSelected(item.diagnosis)"
                @change="toggleItem(item.diagnosis)"
              >
              <div>
                <span class="font-medium">
                  {{ item.diagnosis }}:
                </span>
                {{ item.name }}
              </div>
            </label>
          </div>
        </div>
      </div>
      <div
        v-else
        class="empty-mkb"
      >
        МКБ не найдены
      </div>
    </div>
    <div
      v-if="isView"
      class="overlay"
      @click="toggleDropdown"
    />
  </div>
</template>

<script>
import { storiesService } from '@/services';
import debounce from 'lodash.debounce';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isView: false,
      fullItems: [],
      items: [],
      selectedItems: [],
      search: '',
    };
  },
  computed: {
    groupedData() {
      const grouped = this.items.reduce((acc, item) => {
        if (!acc[item.category]) {
          acc[item.category] = [];
        }
        acc[item.category].push({
          diagnosis: item.diagnosis,
          name: item.name,
          isViewChose: item.isViewChose,
        });
        return acc;
      }, {});

      return Object.keys(grouped).map((key) => ({
        category: key,
        nameCategory: grouped[key][0]?.name,
        diagnoses: grouped[key],
      }));
    },
  },
  watch: {
    selectedItems() {
      this.$emit('input', JSON.stringify(this.selectedItems));
    },
  },
  mounted() {
    this.init();
    if (this.value) {
      this.selectedItems = JSON.parse(this.value);
    }
  },
  methods: {
    toggleDropdown() {
      this.isView = !this.isView;
    },
    isSelected(item) {
      return this.selectedItems.includes(item);
    },
    toggleItem(item) {
      if (this.isSelected(item)) {
        this.selectedItems = this.selectedItems.filter((i) => i !== item);
      } else {
        this.selectedItems.push(item);
      }
    },
    isCategorySelected(category) {
      return this.groupedData.find((group) => group.category === category)
        .diagnoses.every((item) => this.isSelected(item.diagnosis));
    },
    toggleCategory(category) {
      const group = this.groupedData.find((i) => i.category === category)
        .diagnoses.map((i) => i.diagnosis);

      if (this.isCategorySelected(category)) {
        this.selectedItems = this.selectedItems.filter(
          (item) => !group.includes(item),
        );
      } else {
        this.selectedItems.push(...group.filter((item) => !this.isSelected(item)));
      }
    },
    removeItem(item) {
      this.isView = !this.isView;
      this.selectedItems = this.selectedItems.filter((i) => i !== item);
    },
    onSearch: debounce(async function onSearch() { await this.init(); }, 600),
    async init() {
      let list = await storiesService.getMkbList({
        skip: 0,
        take: 350,
        pattern: this.search,
      });
      list = list.map((i) => ({
        category: i.categoryCode || i.code,
        diagnosis: i.code,
        name: i.name,
        isViewChose: !!i.categoryCode,
      }));

      this.items = list;
    },
  },
};
</script>

<style lang="scss">
.multi-select {
  position: relative;
  width: 100%;

  .dropdown {
    position: relative;
    min-height: 38px;
    padding: 10px 12px;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    z-index: 100;
  }

  .tag {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    display: flex;
    align-items: center;
    height: 24px;
    font-size: 14px;
    padding: 0 8px;
    border-radius: 4px;
  }

  .list {
    position: absolute;
    top: calc(100% + 4px);
    width: 100%;
    z-index: 100;
    background: #fff;
    border: 1px solid #CED4DA;
    border-radius: 4px;

    .checkbox {
      display: flex;
      margin-bottom: 12px;
    }

    input[type='checkbox'] {
      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;
      margin-right: 10px;
      border-radius: 4px;
    }

    .list-mkb {
      padding: 12px;
      max-height: 300px;
      overflow: auto;
    }
  }

  .search {
    display: flex;
    align-items: center;
    background: #fff;
    width: 100%;
    padding: 0 12px;
    border-bottom: 1px solid #CED4DA;

    input {
      width: 100%;
      height: 38px;
      border-radius: 4px;
      padding: 0 10px;
      outline: none;
    }
  }

  .custom-scrollbar {
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 100%;
    }

    &::-webkit-scrollbar-track {
      background-color: #e8e8e8;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #aeaeae;
    }
  }

  .empty-mkb {
    padding: 12px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
  }
}
</style>
