<template>
  <div class="stories-container">
    <div class="story-wrapper p-4">
      <div class="d-flex mb-3">
        <h3 class="title mb-0">
          {{ !storyId ? 'Новая история': 'Редактирование истории' }}
        </h3>
        <router-link
          :to="'/stories/'"
          class="ml-auto"
        >
          <b-button
            variant="outline-primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
          >
            К списку историй
          </b-button>
        </router-link>
      </div>
      <div class="story">
        <div class="story-btn-container">
          <b-button
            variant="outline-primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            :pressed="displayContent"
            class="btn-content"
            :class="{ 'bg-white text-dark': displayContent }"
            @click="showContent"
          >
            Контент
          </b-button>
          <b-button
            variant="outline-primary"
            :type="$const.PRIMARY_BUTTON"
            :pressed="displaySettings"
            size="sm"
            class="btn-content ml-2"
            :class="{ 'bg-white text-dark': displaySettings }"
            @click="showSettings"
          >
            Настройки
          </b-button>
        </div>
        <StoryContent
          v-show="displayContent"
          :story="story"
          :story-id="storyId"
          :carousel="storyСarousel"
          :v="$v"
          @uploadAvatar="uploadAvatar"
          @uploadContentFile="uploadContentFile"
          @setAvatarPreview="setAvatarPreview"
          @setMiniPicture="setMiniPicture"
          @setContentPreview="setContentPreview"
          @replaceWithDown="replaceWithDown($event)"
          @replaceWithUp="replaceWithUp($event)"
          @storyContentAdd="storyContentAdd"
          @addContentButtons="addContentButtons"
          @changeShowButtons="changeShowButtons"
          @removeContentButtons="removeContentButtons"
          @removeStoryCarousel="removeStoryCarousel"
        />
        <StorySettings
          v-show="!displayContent"
          :story="story"
          :story-id="storyId"
          :events="storyEvents"
          :dictionary-events="dictionaryEvents"
          :v="$v"
          @onEventBindingChange="changeEventBinding"
          @addCondition="addCondition"
          @storyEventAdd="storyEventAdd"
          @removeEvent="removeEvent"
          @changeEventType="changeEventType"
          @addValueInMkb="addValueInMkb"
          @removeValueInMkb="removeValueInMkb"
        />
      </div>
    </div>
    <b-button
      variant="primary"
      :type="$const.PRIMARY_BUTTON"
      size="sm"
      class="create-btn"
      @click="createHistory"
    >
      {{ !storyId ? 'Создать историю': 'Редактировать историю' }}
    </b-button>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import StoryContent from '@/components/Story/Content/StoryContent.vue';
import StorySettings from '@/components/Story/Settings/StorySettings.vue';
import { mixinRoles } from '@/mixins';
import { validationMixin } from 'vuelidate';
import {
  showValidationErrorMessage,
  showValidationErrorCustomMessage,
  showCustomMessage,
} from '@/helpers/messages';
import { required, maxLength, minValue } from 'vuelidate/lib/validators';

import { storiesService } from '@/services';
import { replacePatterns, pascalToSnake } from '@/helpers/utils';

import { BUTTON_VARIANT, BUTTON_TYPE } from '@doctis.front/doctis.designsystem/stories/components/button/options';

export default {
  name: 'StoryEdit',
  components: {
    StoryContent,
    StorySettings,
  },
  mixins: [validationMixin, mixinRoles],
  props: {
    storyId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      isFileLoading: false,
      checkValidations: false,
      displayContent: true,
      displaySettings: false,
      dictionaryEvents: [],
      story: {
        title: null,
        newUploadFile: null,
        fileUrlEdit: null,
        coverImageFile: null,
        preview: null,
        previewAvatar: null,
        showDurationInDays: 0,
        isHighShowPriority: false,
        isDisplayAtStartup: false,
        eventBinding: false,
      },
      storyСarousel: [
        {
          newUploadFile: null,
          fileUrlEdit: null,
          contentFile: null,
          preview: null,
          showButtons: false,
          buttons: [],
        },
      ],
      storyEvents: [
        {
          event: null,
          conditions: [
            {
              parameter: null,
              comparisonOperator: null,
              value: null,
            },
          ],
        },
      ],
      initialStoryData: null,
      BUTTON_STYLES: [
        {
          id: uuidv4(), text: 'Primary', style: 'primary', pressed: false,
        },
        {
          id: uuidv4(), text: 'Outline', style: 'outline', pressed: false,
        },
        {
          id: uuidv4(), text: 'Light', style: 'light', pressed: false,
        },
        {
          id: uuidv4(), text: 'Ghost', style: 'ghost', pressed: false,
        },
      ],
      BUTTON_VARIANT: [
        {
          id: uuidv4(), text: 'Primary', style: 'main', pressed: false,
        },
        {
          id: uuidv4(), text: 'Outline', style: 'main-alt', pressed: false,
        },
        {
          // id: uuidv4(), text: 'Light', style: 'light', pressed: false,
        },
        {
          // id: uuidv4(), text: 'Ghost', style: 'ghost', pressed: false,
        },
      ],
    };
  },
  validations() {
    return {
      story: {
        title: { maxLength: maxLength(30) },
        newUploadFile: { required: !this.storyId ? required : () => true },
        showDurationInDays: { required, minValue: minValue(1) },
      },
      storyСarousel: {
        $each: {
          newUploadFile(value) {
            return !value.fileUrlEdit ? required(value.newUploadFile) : true;
          },
          buttons: {
            $each: {
              value(value, item) {
                return item?.length && value.type === 'Link' ? required(value.value) : true;
              },
              text(value, item) {
                return item?.length ? required(value.text) : true;
              },
              style(value, item) {
                return item?.length ? required(value.style) : true;
              },
              type(value, item) {
                return item?.length ? required(value.type) : true;
              },
            },
          },
        },
      },
      storyEvents: {
        $each: {
          event: { required: this.story.eventBinding ? required : () => true },
          conditions: {
            $each: {
              parameter: { required: this.story.eventBinding ? required : () => true },
              comparisonOperator: { required: this.story.eventBinding ? required : () => true },
              value: { required: this.story.eventBinding ? required : () => true },
            },
          },
        },
      },
    };
  },
  async created() {
    try {
      if (this.storyId) {
        await this.initStory();
        this.dictionaryEvents = await this.gettingEventDictionary();
      }
      this.dictionaryEvents = await this.gettingEventDictionary();
      if (this.storyId && this.storyEvents?.length) {
        this.story.eventBinding = true;
      }
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    async initStory() {
      const data = await this.getStoryInfo();
      this.initialStoryData = JSON.parse(JSON.stringify(data));

      const { events, carousel, ...storyInfo } = data;

      this.story = { ...storyInfo, preview: null, previewAvatar: null };

      this.story.fileUrlEdit = await this.getImage(this.story.coverImageFile);
      this.storyСarousel = await Promise.all(
        carousel.map(async (item) => {
          if (item.contentFile) {
            item.fileUrlEdit = await this.getImage(item?.contentFile);
          }

          item.buttons = item.buttons.map((el) => ({
            ...el,
            value: el.type === 'Link' ? el.payload.link : null,
            variant: pascalToSnake(el.color).toLowerCase() ?? BUTTON_VARIANT.MAIN,
          }));

          return {
            ...item,
            preview: null,
            previewAvatar: null,
            contentFileContentType: item.contentFileContentType.split('/')[0],
            showButtons: !!item.buttons.length,
          };
        }),
      );
      // this.storyEvents = events;
      this.storyEvents = events.map((event) => ({
        ...event,
        conditions: event.conditions.map((condition) => ({
          ...condition,
          parameter: {
            parameter: condition.parameter,
            dataType: condition.parameterDataType,
          },

        })),
      }));
      console.log(this.storyСarousel);
    },
    async getStoryInfo() {
      try {
        const { data } = await storiesService.getStoryInfo({ storyId: this.storyId });
        return data;
      } catch (e) {
        console.log(e);
        showCustomMessage('error', 'Ошибка', 'При получении истории произошла ошибка');
      }
    },
    gettingEventDictionary() {
      try {
        return storiesService.getDictionaryEvent();
      } catch (e) {
        console.log(e);
        showCustomMessage('error', 'Ошибка', 'Не удалось получить словарь событий');
      }
    },
    showContent() {
      this.displayContent = true;
      this.displaySettings = false;
    },
    showSettings() {
      this.displayContent = false;
      this.displaySettings = true;
    },
    //= =================картинки=====================//
    uploadAvatar(file) {
      this.story.newUploadFile = file;
    },
    uploadContentFile({ contentIndex, file }) {
      this.storyСarousel[contentIndex].newUploadFile = file;
    },
    setAvatarPreview(preview) {
      this.story.previewAvatar = preview;
    },
    setMiniPicture(preview) {
      this.story.preview = preview;
    },
    setContentPreview({ contentIndex, preview }) {
      this.storyСarousel[contentIndex].preview = preview;
    },
    saveFileHistory(file) {
      return storiesService.storyFileUpload(file);
    },
    getImage(file) {
      return storiesService.getStoryImage(file);
    },

    //= ===============================content=====================================//
    changeShowButtons({ historyIndex, value }) {
      if (!value) {
        this.storyСarousel[historyIndex].buttons = [];
      } else {
        this.storyСarousel[historyIndex].buttons = [
          {
            id: uuidv4(),
            value: null,
            text: null,
            type: null,
            style: BUTTON_TYPE.PRIMARY,
            variant: BUTTON_VARIANT.MAIN,
            payload: null,
            styleButtonSelector: this.BUTTON_STYLES.map((style) => ({ ...style })),
          },
        ];
      }
    },
    changeEventBinding({ value }) {
      if (!value) {
        this.storyEvents = [];
      } else {
        this.storyEvents = [
          {
            event: null,
            conditions: [
              {
                parameter: null,
                comparisonOperator: null,
                value: null,
              },
            ],
          },
        ];
      }
    },
    replaceWithDown(index) {
      const actualItem = this.storyСarousel[index];
      const changedItem = this.storyСarousel[index - 1];

      this.storyСarousel.splice(index, 1, changedItem);
      this.storyСarousel.splice(index - 1, 1, actualItem);
    },

    replaceWithUp(index) {
      if (index < this.storyСarousel.length - 1) {
        const actualItem = this.storyСarousel[index];
        const changedItem = this.storyСarousel[index + 1];

        this.storyСarousel.splice(index, 1, changedItem);
        this.storyСarousel.splice(index + 1, 1, actualItem);
      }
    },
    storyContentAdd() {
      const newContent = {
        newUploadFile: null,
        showButtons: false,
        contentFile: null,
        preview: null,
        buttons: [],
      };

      this.storyСarousel.push(newContent);
    },
    addContentButtons({ contentIndex }) {
      const newContentBtn = {
        // id: uuidv4(),
        value: null,
        text: null,
        type: null,
        style: BUTTON_TYPE.PRIMARY,
        variant: BUTTON_VARIANT.MAIN,
        payload: null,
        styleButtonSelector: this.BUTTON_STYLES.map((style) => ({ ...style })),
      };
      this.storyСarousel[contentIndex].buttons.push(newContentBtn);
    },
    removeStoryCarousel(index) {
      this.storyСarousel.splice(index, 1);
    },
    removeContentButtons({ contentIndex, buttonsIndex }) {
      this.storyСarousel[contentIndex].buttons.splice(buttonsIndex, 1);
    },
    //= =================================events============================================//
    storyEventAdd() {
      const newEvent = {
        event: null,
        conditions: [
          {
            parameter: null,
            comparisonOperator: null,
            value: null,
            uniqId: null,
          },
        ],
      };
      this.storyEvents.push(newEvent);
    },
    addCondition({ eventIndex }) {
      const newCondition = {
        parameter: null,
        comparisonOperator: null,
        value: null,
      };
      this.storyEvents[eventIndex].conditions.push(newCondition);
    },
    removeEvent(index) {
      if (this.storyEvents?.length > 1) {
        this.storyEvents.splice(index, 1);
      }
    },
    addValueInMkb({ eventIndex, params }) {
      const { conditionIndex, newValue } = params;
      const valueType = this.storyEvents[eventIndex].conditions[conditionIndex].value;

      if (typeof valueType === 'string') {
        const arr = JSON.parse(this.storyEvents[eventIndex].conditions[conditionIndex].value);
        arr.push(newValue);
        this.storyEvents[eventIndex].conditions[conditionIndex].value = arr;
      }

      if (this.storyEvents[eventIndex].conditions[conditionIndex].value !== null) {
        this.storyEvents[eventIndex].conditions[conditionIndex].value.push(newValue);
      } else {
        this.storyEvents[eventIndex].conditions[conditionIndex].value = [newValue];
      }
    },
    removeValueInMkb({ eventIndex, params }) {
      const { conditionIndex, indexElem } = params;
      const { parameter } = this.storyEvents[eventIndex].conditions[conditionIndex].parameter;
      const valueType = this.storyEvents[eventIndex].conditions[conditionIndex].value;
      if (typeof valueType === 'string' && parameter === 'mkb_code') {
        const arr = JSON.parse(this.storyEvents[eventIndex].conditions[conditionIndex].value);
        arr.splice(indexElem, 1);
        this.storyEvents[eventIndex].conditions[conditionIndex].value = arr;
      } else {
        this.storyEvents[eventIndex].conditions[conditionIndex].value.splice(indexElem, 1);
      }
    },
    changeEventType({ eventIndex, value }) {
      const actualEvent = this.storyEvents[eventIndex];
      actualEvent.event = value;
      if (!value) {
        actualEvent.conditions = [
          {
            parameter: null,
            comparisonOperator: null,
            value: null,
            uniqId: uuidv4(),
          },
        ];
      }
    },
    //= =================Создание=====================//
    async createHistory() {
      this.$v.$touch();
      this.checkValidation = true;

      if (this.$v.$error) {
        showValidationErrorMessage();
        return;
      }

      this.isLoading = true;

      try {
        if (this.storyСarousel && this.storyСarousel.length > 0) {
          const lastCarouselItem = this.storyСarousel[this.storyСarousel.length - 1];
          if (lastCarouselItem.buttons && lastCarouselItem.buttons.length > 0) {
            const hasMoveToNextButton = lastCarouselItem.buttons.some((button) => button.type === 'MoveToNextCarouselItemAction');
            if (hasMoveToNextButton) {
              showValidationErrorCustomMessage('Нельзя разместить кнопку переключения слайдов в последний элемент карусели');
              return;
            }
          }
        }
        if (this.story.newUploadFile) {
          this.isFileLoading = true;

          const data = await this.saveFileHistory(this.story.newUploadFile);
          this.story.coverImageFile = data;
          this.isFileLoading = false;
        }
        if (!this.isFileLoading) { delete this.story.newUploadFile; delete this.story.fileUrlEdit; }

        const responseStoryСarousel = await Promise.all(
          this.storyСarousel?.map(async (item) => {
            const result = {
              id: item.id,
              contentFile: item.newUploadFile ? await this.saveFileHistory(item.newUploadFile) : item.contentFile,
              buttons: item.showButtons
                ? item.buttons?.map((el) => ({
                  id: el.id,
                  text: el.text,
                  type: el.type,
                  style: el.style,
                  payload: { link: el.value },
                  color: replacePatterns(el.variant),
                }))
                : [],
            };
            return result;
          }),
        );

        const responseStoryEvents = this.storyEvents.map((event) => {
          const newConditions = event.conditions.map((condition) => {
            // чекаем если выбран mkb, то приводим массив к стрингифай
            if (condition.parameter !== null
                && condition.parameter.parameter === 'mkb_code'
                && typeof condition.value !== 'string') {
              condition.value = JSON.stringify(condition.value);
            }
            let numericValue;
            if (condition.parameter && condition.parameter.dataType === 'Numeric') {
              numericValue = parseFloat(condition.value);
            } else {
              numericValue = condition.value;
            }

            return {
              parameter: condition.parameter ? condition.parameter.parameter : null,
              comparisonOperator: condition.comparisonOperator,
              value: numericValue,
            };
          });

          return {
            id: event.id,
            event: event.event,
            conditions: newConditions,
          };
        });
        const storyObj = {
          ...this.story,
          carousel: responseStoryСarousel,
          events: this.story.eventBinding ? responseStoryEvents : [],
        };

        const { data } = this.storyId
          ? await storiesService.storyUpdate(storyObj)
          : await storiesService.storyCreate(storyObj);

        if (data?.status === 'success') {
          const message = this.storyId ? 'обновлена' : 'создана';

          showCustomMessage('success', 'Успешно', `История успешно ${message}!`);
          await this.$router.push('/stories/');
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.stories-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: auto;
}
.story-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 88vh;
  .story {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    &-btn-container{
      margin-bottom: 2px;
    }
  }
}
.title {
  font-size: 26px;
}
.btn-content{
  border: none;
}
.btn-content:hover {
  background-color: inherit;
  color: $black;
  font-weight: 700;
}

.bg-white{
  font-weight: 700;
}
.bg-white:focus{
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0) !important;
}
.create-btn {
  position: fixed;
  bottom: 45px;
  right: 124px;
}
</style>
